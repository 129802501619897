<template>
	<div class="page">
		<div class="about">
			<h3 class="text-primary text-center">About Sia Foundation Tracker</h3>
			<p>The Sia Foundation Tracker is a 3rd-party web app that tracks incoming and outgoing transactions to the Sia Foundation's block subsidy addresses.
				Built and maintained by <a href="https://siacentral.com" target="_blank">Sia Central</a>.</p>
			<h3 class="text-primary text-center">About the Sia Foundation</h3>
			<p>"The Sia Foundation is a non-profit corporation that maintains, improves, and promotes the Sia distributed storage network.
				It was founded in 2020 and is funded by a perpetual subsidy built into the Sia protocol. All code, research, educational materials,
				and other products of the Foundation's work will be made available to the public free of charge and under open-source licenses." - <a href="https://sia.tech/siafoundation" target="_blank">Sia Foundation</a></p>
			<div class="resources">
				<a href="https://siacentral.com" target="_blank">
					<svg viewBox="0 0 500 500" version="1.1" xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
						style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;">
						<g>
							<path d="M458.416,264.045c22.691,85.77 -28.347,173.927 -114.108,196.906c-27.249,7.301 -54.752,7.152 -80.342,0.802c40.888,-39.394 59.718,-99.253 43.995,-157.934c-11.115,-41.482 13.539,-84.184 55.022,-95.299c41.482,-11.115 84.184,13.539 95.299,55.021l0.134,0.504Z"
								style="fill:none;stroke:currentColor;stroke-width:30px;" />
							<path d="M170.155,80.336c62.934,-62.536 164.799,-62.414 227.58,0.368c19.948,19.948 33.57,43.841 40.866,69.177c-54.561,-15.713 -115.815,-2.091 -158.773,40.866c-30.367,30.367 -79.675,30.367 -110.042,0c-30.367,-30.367 -30.367,-79.676 0,-110.043l0.369,-0.368Z"
								style="fill:none;stroke:currentColor;stroke-width:30px;" />
							<path d="M155.189,421.832c-85.625,-23.234 -136.451,-111.513 -113.471,-197.274c7.301,-27.249 21.182,-50.993 39.476,-69.979c13.673,55.108 56.096,101.344 114.777,117.068c41.482,11.115 66.136,53.817 55.021,95.3c-11.115,41.482 -53.817,66.136 -95.3,55.021l-0.503,-0.136Z"
								style="fill:none;stroke:currentColor;stroke-width:30px;" />
						</g>
					</svg>
				</a>
				<a href="https://sia.tech" target="_blank">
					<svg viewBox="0 0 257 257" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
						<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							<g id="Sia-Logo" transform="translate(-0.109375, 0.562500)">
								<g transform="translate(0.420058, 0.407811)">
									<path d="M57.2433086,21.3506107 L61.9234926,27.277593 C81.2437494,14.5648555 104.41953,7.25462637 129.288124,7.53264021 C193.48118,8.24984772 246.18186,59.7949795 248.252467,123.958188 C250.495339,193.470177 193.468388,250.497128 123.956398,248.25255 C59.794043,246.181091 8.24976405,193.479558 7.53255655,129.287355 C7.25539551,104.41876 14.5656246,81.2438331 27.2766565,61.9235763 L21.350527,57.2433923 C8.12013796,77.1256464 0.296521293,100.900947 0.00827380721,126.454343 C-0.780569164,196.36715 54.9364759,254.228142 124.97294,255.807534 C197.902965,257.452591 257.45336,197.902196 255.808303,124.972171 C254.227206,54.9357068 196.366213,-0.781338303 126.454259,0.00835747148 C100.900863,0.296604957 77.1255627,8.12107443 57.2433086,21.3506107 Z" id="Fill-1" fill="#7F8C8D"></path>
									<path d="M52.6734757,40.1321517 C52.6734757,47.0586193 47.0586193,52.6734757 40.1321517,52.6734757 C33.205684,52.6734757 27.5908276,47.0586193 27.5908276,40.1321517 C27.5908276,33.205684 33.205684,27.5908276 40.1321517,27.5908276 C47.0586193,27.5908276 52.6734757,33.205684 52.6734757,40.1321517" id="Fill-4" fill="#1ED660"></path>
									<path d="M165.544113,127.920482 L165.544113,165.544454 L127.920994,165.544454 C107.14159,165.544454 90.2970214,148.699032 90.2970214,127.920482 C90.2970214,107.141079 107.14159,90.2965097 127.920994,90.2965097 C148.699544,90.2965097 165.544113,107.141079 165.544113,127.920482 M128.395152,65.2164201 C93.5462017,64.9580207 65.2143733,93.1303748 65.2143733,127.920482 C65.2143733,162.551968 93.2886551,190.626249 127.920994,190.626249 L190.626761,190.626249 L190.626761,128.707619 C190.626761,94.1315658 162.970353,65.472261 128.395152,65.2164201" id="Fill-6" fill="#1ED660"></path>
								</g>
								<circle id="Oval" opacity="0.841843378" fill-rule="nonzero" cx="128.420058" cy="128.407811" r="124"></circle>
							</g>
						</g>
					</svg>
				</a>
			</div>
		</div>
	</div>
</template>

<style lang="stylus" scoped>
.page {
	display: grid;
	align-content: safe center;
	justify-items: center;
}

.about {
	width: 100%;
	padding: 15px;
	max-width: 500px;
}

h3 {
	margin-bottom: 15px;
}

.resources {
	text-align: center;

	a {
		display: inline-block;

		&:not(:last-child) {
			margin-right: 15px;
		}

		svg {
			width: 48px;
		}
	}
}
</style>